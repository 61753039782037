import React, { useEffect, useState, useRef } from 'react';
import useWindowSize from '@rooks/use-window-size';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import logo from '../../assets/images/logo.svg';
import { getDocumentsAction } from '../../actions/documentsActions';
import DocumentPanel from '../../components/DocumentPanel/DocumentPanel';
import BoxPanel from '../../components/BoxPanel/BoxPanel';
import Filters from '../../components/Filters/Filters';
import Shankey from '../../components/Shankey/Shankey';
import Pie from '../../components/Pie/Pie';
import Bars from '../../components/Bars/Bars';

import styles from './Documents.module.scss';
import NoData from '../../components/NoData/NoData';

export default function Documents() {
  const { innerHeight, innerWidth } = useWindowSize();
  const filtersForwardRef = useRef(null);
  const colHeight = (innerHeight - 260) / 2;
  const {
    setValue,
    watch,
    control,
  } = useForm();
  const dispatch = useDispatch();
  const {
    panelIsOpen,
    documentsFiltered,
    filters,
  } = useSelector((state) => state.documentsReducer);

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const shankeyRef = useRef();
  const pieRef = useRef();

  const activeFilters = filtersForwardRef?.current?.getFilters();

  useEffect(() => {
    getDocumentsAction(dispatch);
  }, []);

  useEffect(() => {
    if (documentsFiltered) {
      const newDataset = {
      };
      const shankey = {
        nodes: [],
        links: [],
      };
      const pies = {
        markets: [],
        areas: [],
        applications: [],
      };
      const productBars = [];
      documentsFiltered.forEach((d) => {
        // create shankey data slug
        if (!shankey.nodes.find((n) => n.id === d.business_unit)
          && d?.locations?.length) {
          shankey.nodes.push({
            id: d.business_unit,
            label: d.business_unit,
            type: 'business_unit',
          });
        }
        if (d?.locations?.length) {
          d.locations.forEach((l) => {
            if (!shankey.nodes.find((n) => n.id === l)) {
              shankey.nodes.push({ id: l, label: l, type: 'locations' });
            }
            const index = shankey.links.findIndex((li) => li.source === d.business_unit
              && li.target === l);
            if (index !== -1) {
              shankey.links[index].value += 1;
            } else {
              shankey.links.push({
                source: d.business_unit,
                target: l,
                value: 1,
              });
            }
          });
        }

        if (d.market_area_app.length) {
          d.market_area_app.forEach((item) => {
            if (item.Market) {
              const index = pies.markets.findIndex((n) => n.id === item.Market);
              if (index === -1) {
                pies.markets.push({ id: item.Market, label: item.Market, value: 1 });
              } else {
                pies.markets[index].value += 1;
              }
            } else {
              const index = pies.markets.findIndex((n) => n.id === 'missing');
              if (index === -1) {
                pies.markets.push({ id: 'missing', label: 'missing', value: 1 });
              } else {
                pies.markets[index].value += 1;
              }
            }
            if (item.Area) {
              const index = pies.areas.findIndex((n) => n.id === item.Area);
              if (index === -1) {
                pies.areas.push({ id: item.Area, label: item.Area, value: 1 });
              } else {
                pies.areas[index].value += 1;
              }
            } else {
              const index = pies.areas.findIndex((n) => n.id === 'missing');
              if (index === -1) {
                pies.areas.push({ id: 'missing', label: 'missing', value: 1 });
              } else {
                pies.areas[index].value += 1;
              }
            }
            if (item.Application) {
              const index = pies.applications.findIndex((n) => n.id === item.Application);
              if (index === -1) {
                pies.applications.push({ id: item.Application, label: item.Application, value: 1 });
              } else {
                pies.applications[index].value += 1;
              }
            } else {
              const index = pies.applications.findIndex((n) => n.id === 'missing');
              if (index === -1) {
                pies.applications.push({ id: 'missing', label: 'missing', value: 1 });
              } else {
                pies.applications[index].value += 1;
              }
            }
          });
        }
        if (filters?.markets?.length === 1) {
          pies.markets = [{
            id: filters?.markets[0],
            label: filters?.markets[0],
            value: 100,
          }];
        }
        if (filters?.areas?.length === 1) {
          pies.areas = [{
            id: filters?.areas[0],
            label: filters?.areas[0],
            value: 100,
          }];
        }
        if (filters?.applications?.length === 1) {
          pies.applications = [{
            id: filters?.applications[0],
            label: filters?.applications[0],
            value: 100,
          }];
        }
        if (d.product_type) {
          const productIndex = productBars.findIndex((n) => n.id === d.product_type);
          if (productIndex === -1) {
            productBars.push({ id: d.product_type, label: d.product_type, value: 1 });
          } else {
            productBars[productIndex].value += 1;
          }
        }
        // console.log(d.product_type);
      });
      newDataset.shankey = shankey;
      newDataset.productBars = productBars;
      newDataset.pies = pies;
      setData(newDataset);
      setIsLoading(false);
    }
  }, [documentsFiltered]);

  return (
    <div className={`${styles.container}`}>
      {data
      && <>
        <div className={styles.logo}>
          <img src={logo} alt='logo' />
        </div>
        <Filters
          ref={filtersForwardRef}
          setValue={setValue}
          watch={watch}
          control={control}
        />
        <DocumentPanel
          setValue={setValue}
          watch={watch}
          control={control}
          handleChangeQuery={filtersForwardRef?.current?.handleChangeQuery}
        />
        </>
      }
      <div className={`${styles.dashboard} ${panelIsOpen ? styles.open : ''} ${data && shankeyRef.current ? styles.active : null}`}>
        <div className={styles.row}>
          <div
            ref={shankeyRef}
            className={styles['col-2']}>
            <BoxPanel
              label='BU / LOCALISATIONS'
            >
              {data?.shankey?.nodes?.length > 0 && shankeyRef.current.clientWidth
                && <Shankey
                  data={data.shankey}
                  setSearchParams={filtersForwardRef?.current?.setSearchParams}
                  filters={filters}
                  handleClick={(node) => filtersForwardRef?.current?.handleChangeQuery(
                    node.type,
                    // eslint-disable-next-line no-nested-ternary
                    activeFilters && !activeFilters[node.type].find(
                      (f) => f === node.id.toLowerCase(),
                    )
                      ? [
                        ...activeFilters[node.type].map((f) => ({ value: f, label: f })),
                        { value: node.id.toLowerCase(), label: node.id },
                      ]
                      : activeFilters[node.type].length > 0
                        ? [...activeFilters[node.type].filter((f) => f !== node.id.toLowerCase())
                          .map((f) => ({ value: f.toLowerCase(), label: f }))] : [],
                  )}
                  width={(innerWidth / 2) - 100}
                />
              }
              <NoData
                isNoData={data?.shankey && data?.shankey?.nodes.length === 0}
                isLoading={isLoading}
              />
            </BoxPanel>
          </div>
          <div className={styles['col-2']}>
            <BoxPanel
              label='Product type'
            >
              {data?.productBars?.length > 0 && shankeyRef.current.clientWidth
                && <Bars
                  data={data.productBars}
                  width={data?.productBars?.length < 8
                    ? shankeyRef.current.clientWidth - 60
                    : data.productBars.length * 100
                  }
                  height={colHeight * 0.70}
                  handleClick={(node) => filtersForwardRef?.current?.handleChangeQuery(
                    'product_type',
                    // eslint-disable-next-line no-nested-ternary
                    activeFilters && !activeFilters.product_type.find((f) => f === node.id)
                      ? [
                        ...activeFilters.product_type.map((f) => ({ value: f, label: f })),
                        { value: node.id, label: node.id },
                      ]
                      : activeFilters.product_type.length > 0
                        ? [...activeFilters.product_type.filter((f) => f !== node.id)
                          .map((f) => ({ value: f, label: f }))] : [],
                  )}
                  right={10}
                  left={30}
                  bottom={20}
                  top={15}
                />
              }
              <NoData
                isNoData={data?.productBars?.length === 0 && shankeyRef.current.clientWidth}
                isLoading={isLoading}
              />
            </BoxPanel>
          </div>
        </div>
        <div className={styles.row}>
          <div ref={pieRef} className={styles['col-3']}>
            <BoxPanel
              label='Markets'
            >
              {data?.pies?.markets.length > 0
                && <Pie
                  data={data?.pies.markets}
                  width={(innerWidth / 3) - 80}
                  height={colHeight}
                  handleClick={(val) => filtersForwardRef?.current?.handleChangeQuery(
                    'markets',
                    activeFilters?.markets !== val ? [{ value: val, label: val }] : [],
                  )}
                  colorsRange={['#d2fafa', '#48B1B1']}
                />
              }
              <NoData
                isNoData={data?.pies?.markets.length === 0}
                isLoading={isLoading}
              />
            </BoxPanel>
          </div>
          <div className={styles['col-3']}>
            <BoxPanel
              label='Areas'
            >
              {data?.pies?.areas.length > 0 && pieRef?.current?.clientHeight > 0
                && <Pie
                  data={data?.pies.areas}
                  width={(innerWidth / 3) - 80}
                  height={colHeight}
                  handleClick={(val) => filtersForwardRef?.current?.handleChangeQuery(
                    'areas',
                    activeFilters?.areas !== val ? [{ value: val, label: val }] : [],
                  )}
                  colorsRange={['#92b9e8', '#2E64A3']}
                />
              }
              <NoData
                isNoData={data?.pies?.areas.length === 0}
                isLoading={isLoading}
              />
            </BoxPanel>

          </div>
          <div className={styles['col-3']}>
            <BoxPanel
              label='Applications'
            >
              {data?.pies?.applications.length > 0 && pieRef?.current?.clientHeight > 0
                && <Pie
                  data={data?.pies?.applications}
                  width={(innerWidth / 3) - 80}
                  handleClick={(val) => filtersForwardRef?.current?.handleChangeQuery(
                    'applications',
                    activeFilters?.applications !== val ? [{ value: val, label: val }] : [],
                  )}
                  height={colHeight}
                  colorsRange={['#f2cbb8', '#F16E30']}
                />
              }
              <NoData
                isNoData={data?.pies?.applications.length === 0}
                isLoading={isLoading}
              />
            </BoxPanel>
          </div>
        </div>
      </div>
      <NoData
        isLoading={!data}
      />
    </div>
  );
}
