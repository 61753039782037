import React, { useState, useRef } from 'react';
import { IoChevronDownOutline } from 'react-icons/io5';
import styles from './BoxPanel.module.scss';

export default function BoxPanel({ label, children }) {
  const [isOpen, setIsOpen] = useState(true);
  const contentRef = useRef();
  return (
    <div
      className={`${styles.container}`}
      style={{
        height: isOpen && contentRef?.current?.clientHeight
          ? contentRef.current.clientHeight + 48 : 48,
      }}
      >
      <div className={`${styles.title}`}>
        <h2 className={styles.label}>
          {label}
        </h2>
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className={`${styles.toggle} ${isOpen ? styles.open : ''}`}>
          <IoChevronDownOutline size={20}/>
        </button>
      </div>
      <div
        className={styles['section-content']}
        style={{
          opacity: !isOpen ? 0 : 1,
        }}
        >
        <div ref={contentRef} className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  );
}
