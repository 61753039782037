import React from 'react';
import useWindowSize from '@rooks/use-window-size';
import { Route, Routes } from 'react-router-dom';
import Documents from './pages/Documents/Documents';

function App() {
  const { innerWidth } = useWindowSize();
  return (
    <div className="App">
      {innerWidth > 1024
        ? <Routes>
          <Route
            path="/"
            element={<Documents />}
          />
        </Routes>
        : <div className="mobile">
          <p>This application is not available<br/>for your device</p>
        </div>
      }
    </div>
  );
}

export default App;
