import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { animated, useSpring } from 'react-spring';
import styles from './Bars.module.scss';

const animationDuration = 200;
const animationConfig = {
  to: async (next) => {
    await next({ t: 1 });
  },
  from: { t: 0 },
  config: { duration: animationDuration },
  reset: true,
};

const XAxis = ({
  bottom, left, height, scale,
}) => {
  const axis = useRef(null);

  useEffect(() => {
    d3.select(axis.current)
      .transition()
      .duration(animationDuration)
      .call(d3.axisBottom(scale));
  });

  return (
    <g
      ref={axis}
      className={`${styles.axis} ${styles.x}`}
      transform={`translate(${left}, ${height - bottom})`}
    />
  );
};

const YAxis = ({
  top, left, scale,
}) => {
  const axis = useRef(null);

  useEffect(() => {
    d3.select(axis.current)
      .transition()
      .duration(animationDuration)
      .call(d3.axisLeft(scale));
  });

  return (
    <g
      ref={axis}
      className={`${styles.axis} ${styles.y}`}
      transform={`translate(${left},${top})`}
    />
  );
};

const Rect = ({
  index,
  data,
  prev,
  next,
  x,
  y,
  height,
  top,
  bottom,
  colors,
  clickOnNode,
}) => {
  const [styledAnime, api] = useSpring(() => animationConfig);
  api.start(animationConfig);

  const prevIndex = prev.findIndex((d) => d.label === next[index].label);
  const interpolatorValue = d3.interpolate(prev[prevIndex] ? prev[prevIndex].value : 0, data.value);
  const interpolatorX = d3.interpolate(
    prev[prevIndex] ? x(prev[prevIndex].label) : 0,
    x(data.label),
  );
  console.log(data.value);
  const interpolatorY = d3.interpolate(
    y(prev[prevIndex] ? prev[prevIndex].value : data.value),
    y(data.value),
  );
  const barHeight = styledAnime.t.to((t) => height - bottom - top - interpolatorY(t));

  return (
    <animated.g
      key={data.index}
      transform={styledAnime.t.to((t) => `translate(${interpolatorX(t)},${interpolatorY(t)})`)}
    >
      <animated.rect
        width={x.bandwidth()}
        height={barHeight}
        fill={colors(data.value)}
        onClick={(e) => clickOnNode(e)}
      />
      <animated.text
        transform={styledAnime.t.to(() => `translate(${x.bandwidth() / 2},${-10})`)}
        textAnchor="middle"
        alignmentBaseline="middle"
        fontSize="10"
        fill={'#8f8f8f'}
        >
        {styledAnime.t.to((t) => interpolatorValue(t).toFixed())}
      </animated.text>
    </animated.g>
  );
};

const Bars = ({
  data,
  width,
  height,
  left,
  right,
  bottom,
  top,
  handleClick,
}) => {
  const cache = useRef(data);
  const updatedData = useRef(data);
  const max = Math.max(...data.map((d) => d.value));
  const min = 0;
  const colors = d3.scaleLinear()
    .domain([min, max])
    .range(['#98eaeb', '#42B1B2']);

  updatedData.current = [...data].sort((a, b) => b.value - a.value);

  const x = d3.scaleBand()
    .range([0, width - left - right])
    .domain(updatedData.current.map((d) => d.label))
    .padding(0.1);

  const y = d3.scaleLinear()
    .domain([max, min])
    .range([0, height - top - bottom]);

  function clickOnNode(e, d) {
    e.preventDefault();
    handleClick(d);
  }

  useEffect(() => {
    cache.current = updatedData.current;
  });
  return (
    <div className={styles.bars}>
      <svg width={width} height={height}>
        <XAxis
          scale={x}
          left={left}
          height={height}
          bottom={bottom}
        />
        <YAxis
          scale={y}
          left={left}
          top={top}
        />
        <g transform={`translate(${left},${top})`}>
          {data.map((d, i) => (
            <Rect
              key={d.id}
              index={i}
              data={d}
              prev={cache.current}
              next={updatedData.current}
              colors={colors}
              clickOnNode={(e) => clickOnNode(e, d)}
              x={x}
              y={y}
              top={top}
              bottom={bottom}
              height={height}
            />
          ))}
        </g>
      </svg>
    </div>
  );
};

export default Bars;
