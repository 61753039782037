import React, {
  useEffect, useState, useRef, useImperativeHandle, forwardRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom';
import { setProductsFiteredAction } from '../../actions/documentsActions';
import StyledSelect from '../../lib/HooksFormFields/StyledSelect';
import { createOptions, arrayChecker } from '../../utils/utils';
import circleDotted from '../../assets/images/circle-dotted.svg';
import RangeSlider from '../RangeSlider/RangeSlider';
import styles from './Filters.module.scss';

function Filters({
  setValue,
  watch,
  control,
}, ref) {
  const dispatch = useDispatch();
  const {
    optionsLists,
    documentsList,
    panelIsOpen,
    // documentsFiltered,
    filters,
  } = useSelector((state) => state.documentsReducer);
  const [searchParams, setSearchParams] = useSearchParams();
  const [options, setOptions] = useState({});
  const [isAdvenced, setIsAdvenced] = useState(false);
  const location = useLocation();

  const filtersRef = useRef();

  function queryToObject(string) {
    const urlParams = new URLSearchParams(string);
    const entries = urlParams.entries();
    const result = {};
    // eslint-disable-next-line
    for(const [key, value] of entries) {
      if (key === 'value') {
        result[key] = value.split(',').map((v) => Number(v));
      } else {
        result[key] = value;
      }
    }
    return result;
  }

  function toQueryString(obj) {
    return Object.keys(obj).map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
      .join('&');
  }

  function handleChangeQuery(key, value) {
    const updatedFilters = { ...filters };
    if (key !== 'propertiesValues' && key !== 'materialsValues') {
      updatedFilters[key] = value.map((v) => v.value);
      if (key === 'properties') {
        updatedFilters.propertiesValues = updatedFilters.propertiesValues.filter(
          (v) => value.find((opt) => opt.value === v.label),
        );
      }
      if (key === 'materials') {
        updatedFilters.materialsValues = updatedFilters.materialsValues.filter(
          (v) => value.find((opt) => opt.value === v.label),
        );
      }
    }
    if (updatedFilters[key] && key === 'propertiesValues') {
      let valuesArray = [...updatedFilters.propertiesValues];
      const propValues = value;
      if (updatedFilters.properties.find((p) => p === propValues.label)) {
        if (valuesArray.find((v) => v.label === propValues.label)) {
          valuesArray = [...valuesArray.filter((v) => v.label !== propValues.label), propValues];
        } else {
          valuesArray = [...valuesArray, propValues];
        }
      } else {
        valuesArray = valuesArray.filter((v) => v.label !== propValues.label) || [];
      }
      updatedFilters[key] = valuesArray;
    }
    updatedFilters.propertiesValues = updatedFilters.propertiesValues.map((v) => toQueryString(v));

    if (updatedFilters[key] && key === 'materialsValues') {
      let valuesArray = [...updatedFilters.materialsValues];
      const propValues = value;
      if (updatedFilters.materials.find((p) => p === propValues.label)) {
        if (valuesArray.find((v) => v.label === propValues.label)) {
          valuesArray = [...valuesArray.filter((v) => v.label !== propValues.label), propValues];
        } else {
          valuesArray = [...valuesArray, propValues];
        }
      } else {
        valuesArray = valuesArray.filter((v) => v.label !== propValues.label) || [];
      }
      updatedFilters[key] = valuesArray;
    }
    updatedFilters.materialsValues = updatedFilters.materialsValues.map((v) => toQueryString(v));

    setSearchParams(updatedFilters);
  }

  async function handleChangeUrl() {
    const updatedFilters = {
      document_name: [],
      product_name: [],
      product_family: [],
      product_type: [],
      business_unit: [],
      locations: [],
      materials: [],
      materialsValues: [],
      areas: [],
      markets: [],
      applications: [],
      properties: [],
      propertiesValues: [],
    };
    // eslint-disable-next-line
    for (const entry of searchParams.entries()) {
      const [key, value] = entry;
      if (key !== 'propertiesValues' && key !== 'materialsValues') {
        updatedFilters[key].push(value);
      }
    }
    // eslint-disable-next-line
    for (const entry of searchParams.entries()) {
      const [key, value] = entry;
      if (updatedFilters[key] && key === 'propertiesValues') {
        const obj = queryToObject(value);
        if (obj.label && updatedFilters.properties.find((p) => p === obj.label)) {
          updatedFilters[key].push(obj);
        }
      }
      if (updatedFilters[key] && key === 'materialsValues') {
        const obj = queryToObject(value);
        if (obj.label && updatedFilters.materials.find((p) => p === obj.label)) {
          updatedFilters[key].push(obj);
        }
      }
    }

    console.log(updatedFilters);

    let updatedProducts = [];

    if (updatedFilters.document_name.length) {
      updatedProducts = documentsList.filter((p) => {
        if (
          updatedFilters.document_name.find((n) => n === p.document_name)
        ) {
          return p;
        }
        return null;
      });
    } else {
      updatedProducts = documentsList;
    }

    if (updatedFilters.product_name.length) {
      updatedProducts = updatedProducts.filter((p) => {
        if (
          updatedFilters.product_name.find((n) => n === p.product_name)
        ) {
          return p;
        }
        return null;
      });
    }

    if (updatedFilters.product_family.length) {
      updatedProducts = updatedProducts.filter((p) => {
        if (
          updatedFilters.product_family.find((n) => n === p.product_family)
        ) {
          return p;
        }
        return null;
      });
    }

    if (updatedFilters.product_type.length) {
      updatedProducts = updatedProducts.filter((p) => {
        if (
          updatedFilters.product_type.find((n) => n === p.product_type)
        ) {
          return p;
        }
        return null;
      });
    }

    if (updatedFilters.business_unit.length) {
      updatedProducts = updatedProducts.filter((p) => {
        if (
          updatedFilters.business_unit.find((n) => n === p.business_unit)
        ) {
          return p;
        }
        return null;
      });
    }

    if (updatedFilters.locations.length) {
      updatedProducts = updatedProducts.filter((p) => {
        if (p.locations
          && arrayChecker(p.locations.map((l) => l.toLowerCase()), updatedFilters.locations)) {
          return p;
        }
        return null;
      });
    }

    if (updatedFilters.areas.length) {
      updatedProducts = updatedProducts.filter((p) => {
        const areas = p.market_area_app.filter((item) => (item || null)).map((item) => item.Area);
        if (arrayChecker(areas, updatedFilters.areas)) {
          return p;
        }
        return null;
      });
    }

    if (updatedFilters.markets.length) {
      updatedProducts = updatedProducts.filter((p) => {
        const markets = p.market_area_app.filter((item) => (item || null))
          .map((item) => item.Market);
        if (arrayChecker(markets, updatedFilters.markets)) {
          return p;
        }
        return null;
      });
    }

    if (updatedFilters.applications.length) {
      updatedProducts = updatedProducts.filter((p) => {
        const applications = p.market_area_app.filter((item) => (item || null))
          .map((item) => item.Application);
        if (arrayChecker(applications, updatedFilters.applications)) {
          return p;
        }
        return null;
      });
    }

    if (updatedFilters.business_unit.length) {
      updatedProducts = updatedProducts.filter((p) => {
        if (
          updatedFilters.business_unit.find((n) => n === p.business_unit)
        ) {
          return p;
        }
        return null;
      });
    }

    // if (updatedFilters.properties.length) {
    //   updatedProducts = updatedProducts.filter((p) => {
    //     if (
    //       updatedFilters.properties.find((n) => n === p.properties.find((m) => m === n))
    //     ) {
    //       return p;
    //     }
    //     return null;
    //   });
    // }

    // if (updatedFilters.materials.length) {
    //   updatedProducts = updatedProducts.filter((p) => {
    //     if (
    //       updatedFilters.materials.find((n) => n === p.materials.find((m) => m === n))
    //     ) {
    //       return p;
    //     }
    //     return null;
    //   });
    // }

    if (updatedFilters.properties.length) {
      updatedProducts = updatedProducts.filter((p) => {
        if (arrayChecker(p.properties, updatedFilters.properties)) {
          return p;
        }
        return null;
      });
    }

    if (updatedFilters.materials.length) {
      updatedProducts = updatedProducts.filter((p) => {
        if (arrayChecker(p.materials, updatedFilters.materials)) {
          return p;
        }
        return null;
      });
    }

    const updatedOptions = createOptions(updatedProducts, {
      Market: updatedFilters.markets[0] || null,
      Area: updatedFilters.areas[0] || null,
      Application: updatedFilters.applications[0] || null,
    }, options);

    setOptions(updatedOptions);

    if (updatedFilters.propertiesValues.length) {
      let productsByProperty = [...updatedProducts];
      updatedFilters.propertiesValues.forEach((v) => {
        const [min, max] = v.value;
        productsByProperty = productsByProperty.filter((p) => {
          const opt = options?.propertiesOptions?.find((o) => o.value === v.label);
          if (opt?.max === max && opt?.min === min) {
            return p;
          }
          if (p[v.label] && p[v.label][0]) {
            let isIn = true;
            const prop = p[v.label][0];
            if (prop?.min && prop.min >= max) {
              isIn = false;
            }
            if (prop?.max && prop.max <= min) {
              isIn = false;
            }
            if (prop?.value && (prop.value < min || prop.value > max)) {
              isIn = false;
            }
            if (isIn && (prop.value || prop.min || prop.max)) {
              return p;
            }
          }
          return null;
        });
      });
      updatedProducts = productsByProperty;
    }

    if (updatedFilters.materialsValues.length) {
      let productsByMaterial = [...updatedProducts];
      updatedFilters.materialsValues.forEach((v) => {
        const [min, max] = v.value;
        productsByMaterial = productsByMaterial.filter((p) => {
          if (options) {
            const opt = options?.materialsOptions?.find((o) => o.value === v.label);
            if (opt?.max === max && opt.min === min) {
              return p;
            }
          }
          if (p[v.label] && p[v.label][0]) {
            const prop = p[v.label][0];
            let isIn = true;
            if (prop?.min && prop.min >= max) {
              isIn = false;
            }
            if (prop?.max && prop.max <= min) {
              isIn = false;
            }
            if (prop?.value && (prop.value < min || prop.value > max)) {
              isIn = false;
            }
            if (isIn && (prop.value || prop.min || prop.max)) {
              return p;
            }
          }
          return null;
        });
      });
      updatedProducts = productsByMaterial;
    }

    setValue('document_name', updatedFilters.document_name.map((n) => ({ label: n, value: n.toLowerCase() })));
    setValue('product_name', updatedFilters.product_name.map((n) => ({ label: n, value: n.toLowerCase() })));
    setValue('product_family', updatedFilters.product_family.map((n) => ({ label: n, value: n.toLowerCase() })));
    setValue('business_unit', updatedFilters.business_unit.map((n) => ({ label: n, value: n.toLowerCase() })));
    setValue('locations', updatedFilters.locations.map((n) => ({ label: n.toUpperCase(), value: n.toLowerCase() })));
    setValue('product_type', updatedFilters.product_type.map((n) => ({ label: n, value: n.toLowerCase() })));
    setValue('materials', updatedFilters.materials.map((n) => ({ label: n, value: n.toLowerCase() })));
    setValue('properties', updatedFilters.properties.map((n) => ({ label: n, value: n.toLowerCase() })));
    setValue('areas', updatedFilters.areas?.map((n) => ({ label: n, value: n.toLowerCase(), color: '#7AA6D9' }))[0] || null);
    setValue('markets', updatedFilters.markets?.map((n) => ({ label: n, value: n.toLowerCase(), color: '#A0DEDF' }))[0] || null);
    setValue('applications', updatedFilters?.applications.map((n) => ({ label: n, value: n.toLowerCase(), color: '#F1BEA4' }))[0] || null);

    await setProductsFiteredAction(
      dispatch,
      {
        filters: updatedFilters,
        documents: updatedProducts,
      },
    );
  }

  useImperativeHandle(ref, () => ({
    setSearchParams: (obj) => setSearchParams(obj),
    handleChangeQuery: (key, value) => handleChangeQuery(key, value),
    getFilters: () => ({
      markets: filters?.markets?.length > 0 ? filters.markets[0] : null,
      areas: filters?.areas?.length > 0 ? filters.areas[0] : null,
      applications: filters?.applications?.length > 0 ? filters.applications[0] : null,
      business_unit: filters.business_unit || [],
      locations: filters.locations || [],
      product_type: filters.product_type || [],
    }),
  }));

  useEffect(() => {
    document.addEventListener(
      'onpushstate',
      (event) => {
        setSearchParams(event.detail.query);
      },
      false,
    );
  }, [document]);

  useEffect(() => {
    if (documentsList) {
      handleChangeUrl();
    }
  }, [location.search, documentsList]);

  useEffect(() => {
    if (optionsLists) {
      setOptions(optionsLists);
    }
  }, [optionsLists]);

  return (
    <div
      ref={filtersRef}
      className={`${styles.container} ${options?.document_name ? styles.active : null} ${panelIsOpen ? styles['panel-is-open'] : ''}`}>
      <div className={styles.reset}>
        <button onClick={() => setSearchParams()}>
          Reset all filters
        </button>
      </div>
      {!isAdvenced
        ? <div className={styles['select-container']}>
            <div className={`${styles.row}`}>
              <div className={`${styles.select} ${!panelIsOpen ? styles['no-panel'] : ''}`}>
                <label htmlFor="product_family">Product family</label>
                <StyledSelect
                  control={control}
                  name="product_family"
                  isMulti
                  placeholder="Search"
                  value={watch('product_family')}
                  onChange={(array) => handleChangeQuery('product_family', array)}
                  options={options?.product_family}
                  />
              </div>
              <div className={`${styles.select} ${!panelIsOpen ? styles['no-panel'] : ''}`}>
                <label htmlFor="materials">Materials</label>
                <StyledSelect
                  control={control}
                  name="materials"
                  isMulti
                  placeholder="Search"
                  value={watch('materials')}
                  onChange={(array) => handleChangeQuery('materials', array)}
                  options={options?.materials}
                  />
              </div>
              {!panelIsOpen
                && <div className={`${styles.select} ${!panelIsOpen ? styles['no-panel'] : ''}`}>
                  <label htmlFor="business_unit">Business unit</label>
                  <StyledSelect
                    control={control}
                    name="business_unit"
                    isMulti
                    placeholder="Search"
                    value={watch('business_unit')}
                    onChange={(array) => handleChangeQuery('business_unit', array)}
                    options={options?.business_unit}
                    />
                </div>
              }
            </div>
            <div className={`${styles.row}`}>
              <div className={`${styles.select} ${!panelIsOpen ? styles['no-panel'] : ''}`}>
                <label htmlFor="product_type">Product type</label>
                <StyledSelect
                  control={control}
                  name="product_type"
                  isMulti
                  placeholder="Search"
                  value={watch('product_type')}
                  onChange={(array) => handleChangeQuery('product_type', array)}
                  options={options?.product_type}
                  />
              </div>
              <div className={`${styles.select} ${!panelIsOpen ? styles['no-panel'] : ''}`}>
                <label htmlFor="properties">Properties</label>
                <StyledSelect
                  control={control}
                  name="properties"
                  isMulti
                  placeholder="Search"
                  value={watch('properties')}
                  onChange={(array) => handleChangeQuery('properties', array)}
                  options={options?.properties}
                  />
              </div>
              {!panelIsOpen
                && <div className={`${styles.select} ${!panelIsOpen ? styles['no-panel'] : ''}`}>
                  <label htmlFor="locations">Locations</label>
                  <StyledSelect
                    control={control}
                    name="locations"
                    isMulti
                    placeholder="Search"
                    value={watch('locations')}
                    onChange={(array) => handleChangeQuery('locations', array)}
                    options={options?.locations}
                    />
                </div>
              }
            </div>
          </div>
        : <div className={`${styles['select-container']} ${styles.advenced} ${panelIsOpen ? styles.panel : ''}`}>
            <div className={`${styles.row}`}>
              <div className={`${styles.select} ${!panelIsOpen ? styles['no-panel'] : ''}`}>
                <label htmlFor="product_family">Product family</label>
                <StyledSelect
                  control={control}
                  name="product_family"
                  isMulti
                  placeholder="Search"
                  value={watch('product_family')}
                  onChange={(array) => handleChangeQuery('product_family', array)}
                  options={options?.product_family}
                  />
              </div>
              <div className={`${styles.select} ${!panelIsOpen ? styles['no-panel'] : ''}`}>
                <label htmlFor="product_type">Product type</label>
                <StyledSelect
                  control={control}
                  name="product_type"
                  isMulti
                  placeholder="Search"
                  value={watch('product_type')}
                  onChange={(array) => handleChangeQuery('product_type', array)}
                  options={options?.product_type}
                  />
              </div>
            </div>
            <div className={`${styles.row} ${styles.border}`}>
              <div className={`${styles.select} ${!panelIsOpen ? styles['no-panel'] : ''}`}>
                <label htmlFor="markets">Markets</label>
                <StyledSelect
                  control={control}
                  name="markets"
                  isMulti
                  isClearable={true}
                  placeholder="Search"
                  value={watch('markets')}
                  onChange={(array) => handleChangeQuery('markets', array || [])}
                  options={watch('markets') ? [] : options?.markets}
                  />
              </div>
              <div className={`${styles.select} ${!panelIsOpen ? styles['no-panel'] : ''}`}>
                <label htmlFor="areas">Areas</label>
                <StyledSelect
                  control={control}
                  name="areas"
                  isMulti
                  placeholder="Search"
                  isClearable={true}
                  value={watch('areas')}
                  onChange={(array) => handleChangeQuery('areas', array || [])}
                  options={watch('areas') ? [] : options?.areas}
                  />
              </div>
              <div className={`${styles.select} ${!panelIsOpen ? styles['no-panel'] : ''}`}>
                <label htmlFor="applications">Applications</label>
                <StyledSelect
                  control={control}
                  name="applications"
                  isMulti
                  isClearable={true}
                  placeholder="Search"
                  value={watch('applications')}
                  onChange={(array) => handleChangeQuery('applications', array || [])}
                  options={watch('applications') ? [] : options?.applications}
                  />
              </div>
            </div>
            <div className={`${styles.row} ${styles.border}`}>
              <div className={`${styles.select} ${styles['col-2']}`}>
                <label htmlFor="business_unit">Business unit</label>
                <StyledSelect
                  control={control}
                  name="business_unit"
                  isMulti
                  placeholder="Search"
                  value={watch('business_unit')}
                  onChange={(array) => handleChangeQuery('business_unit', array)}
                  options={options?.business_unit}
                  />
              </div>
              <div className={`${styles.select} ${styles['col-2']}`} >
                <label htmlFor="locations">Locations</label>
                <StyledSelect
                  control={control}
                  name="locations"
                  isMulti
                  placeholder="Search"
                  value={watch('locations')}
                  onChange={(array) => handleChangeQuery('locations', array)}
                  options={options?.locations}
                  />
              </div>
            </div>
            <div className={`${styles.row} ${styles.border}`}>
              <div className={`${styles.select} ${styles['col-2']}`}>
                <label htmlFor="materials">Materials</label>
                <StyledSelect
                  control={control}
                  name="materials"
                  isMulti
                  placeholder="Search"
                  value={watch('materials')}
                  onChange={(array) => handleChangeQuery('materials', array)}
                  options={options?.materials}
                  />
              </div>
            </div>
            <div className={styles.ranges}>
              {options?.materialsOptions.filter((p) => watch('materials')
                ?.find((pr) => pr.label === p.label)).map((p) => <RangeSlider
                key={p.label}
                onChange={(value) => handleChangeQuery('materialsValues', value)}
                defaultValue={filters.materialsValues.find((v) => v.label === p.label)?.value}
                property={p}
              />)}
            </div>
            <div className={`${styles.row} ${styles.border}`}>
              <div className={`${styles.select} ${styles['col-2']}`} >
                <label htmlFor="properties">Properties</label>
                <StyledSelect
                  control={control}
                  name="properties"
                  isMulti
                  placeholder="Search"
                  value={watch('properties')}
                  onChange={(array) => handleChangeQuery('properties', array)}
                  options={options?.properties}
                  />
              </div>
            </div>
            <div className={styles.ranges}>
              {filters.propertiesValues && options?.propertiesOptions.filter((p) => watch('properties')
                ?.find((pr) => pr.label === p.label)).map((p) => <RangeSlider
                key={p.label}
                onChange={(value) => handleChangeQuery('propertiesValues', value)}
                range={filters.propertiesValues.find((v) => v.label === p.label)?.value}
                property={p}
              />)}
            </div>
        </div>
      }
      <button className={styles.search} onClick={() => setIsAdvenced(!isAdvenced)}>
        <div className={`${styles.icon} ${isAdvenced ? styles.open : ''}`}>
          <img src={circleDotted} alt='search-btn' />
        </div>
        <p className={styles.label}>Advanced search</p>
      </button>
    </div>
  );
}

export default forwardRef(Filters);
