import uniqid from 'uniqid';
// import slugify from 'slugify';
import {
  GET_DOCUMENTS, SET_DOCUMENTS_FILTERED,
} from './types';
import data from '../constants/data.json';
import { createOptions } from '../utils/utils';

export async function getDocumentsAction(dispatch) {
  let documents = data.products.map((p) => ({
    ...p,
    _id: uniqid(),
    // business_unit: slugify(p.business_unit).toLowerCase(),
  })).sort((a, b) => {
    if (a.document_name < b.document_name) { return -1; }
    if (a.document_name > b.document_name) { return 1; }
    return 0;
  });

  documents = documents.map((d) => {
    let key;
    const keys = Object.keys(d);
    let n = keys.length;
    const newobj = { };
    while (n--) {
      key = keys[n];
      newobj[key.toLowerCase()] = d[key];
    }
    return newobj;
  });

  const optionsLists = createOptions(documents, {
    Market: null,
    Area: null,
    Application: null,
  });

  documents.forEach((d) => {
    d.market_area_app.forEach((m) => {
      // eslint-disable-next-line
      for (const property in m) {
        if (!m[property]) {
          // eslint-disable-next-line no-param-reassign
          m[property] = 'missing';
        }
      }
    });
  });

  dispatch({
    type: GET_DOCUMENTS,
    payload: {
      documents,
      optionsLists,
    },
  });
}

export async function setProductsFiteredAction(dispatch, obj) {
  dispatch({
    type: SET_DOCUMENTS_FILTERED,
    payload: obj,
  });
}
