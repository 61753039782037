import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import styles from './RangeSlider.module.scss';
import 'rc-slider/assets/index.css';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

export default function RangeSlider({
  property,
  range,
  onChange,
}) {
  const min = property.min * 100;
  const max = property.max * 100;
  const [values, setValues] = useState([min, max]);

  const marks = {
    [min]: <span>{property.min}</span>,
    [max]: <span>{property.max}</span>,
  };

  function handleChangeValue(array) {
    setValues(array);
  }

  function handleChangeQuery(array) {
    onChange({
      label: property.label,
      value: [array[0] / 100, array[1] / 100],
    });
  }

  useEffect(() => {
    if (range) {
      setValues([range[0] * 100, range[1] * 100]);
    }
  }, [range]);

  return (
    <div className={`${styles.container}`}>
      <label>{property.label} {property.unit && `(${property.unit})`}</label>
      <div className={styles.slider}>
        <Range
          allowCross={false}
          marks={marks}
          value={values}
          min={min}
          max={max}
          onChange={handleChangeValue}
          onAfterChange={(val) => handleChangeQuery(val)}
          tipFormatter={(val) => ` ${val > min && val < max ? val / 100 : ''} `}
          tipProps={{
            placement: 'bottom',
            visible: true,
          }}
          />
      </div>
    </div>
  );
}
