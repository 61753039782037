import React, { useRef, useState } from 'react';
import { IoChevronDown } from 'react-icons/io5';
import uniqid from 'uniqid';
import styles from './DocumentRow.module.scss';

export default function DocumentRow({ document }) {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef();
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
        {document.url
          ? <a href={document.url} target="_blank" rel="noreferrer">
              <h3>{document?.product_name}</h3>
            </a>
          : <h3>{document?.product_name}</h3>
          }
          <p>
            <span>Business unit : {document?.business_unit}</span><br/>
            <span>Product family : {document?.product_family}</span>
          </p>
        </div>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`${styles.toggle} ${isOpen ? styles.active : ''}`}>
          <IoChevronDown size={20} />
        </button>
      </div>
      <div
        className={styles.panel}
        style={{
          height: isOpen && contentRef.current?.clientHeight
            ? contentRef.current.clientHeight + 30 : 0,
        }}
      >
        <div ref={contentRef}>
          <p>
            <span className={styles.label}>Product family : </span>
            <span>{document?.product_family}</span><br/>
            <span className={styles.label}>Product type : </span>
            <span>{document?.product_type}</span><br/>
          </p>
          {document?.properties.length > 0
            && <>
              <span className={styles.label}>Product properties : </span>
              <p className={styles.list}>{document?.properties.map((p) => <span
                key={uniqid() + document._id}>
                  {' '} {p} /
                  </span>)}
              </p>
            </>
          }
          {document?.materials.length > 0
            && <>
              <span className={styles.label}>Product materials : </span>
              <p className={styles.list}>{document?.materials.map((p) => <span
                key={uniqid() + document._id}>
                  {' '} {p} /
                  </span>)}
              </p>
            </>
          }
          {document?.benefits
            && <>
              <span className={styles.label}>Benefits :</span>
              <p>
              {document?.benefits}
            </p>
            </>
          }
        </div>
      </div>
    </div>
  );
}
