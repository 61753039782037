import React, { useRef, useState } from 'react';
import {
  sankey as d3sankey,
  sankeyLinkHorizontal,
  sankeyJustify,
} from 'd3-sankey';
import * as d3 from 'd3';
import styles from './Shankey.module.scss';

const Rect = ({
  index,
  id,
  x0,
  x1,
  y0,
  y1,
  name,
  color,
  width,
  handleClick,
}) => (
  <>
    <rect
      id={id}
      x={x0}
      y={y0}
      width={x1 - x0}
      height={y1 - y0}
      fill={d3.rgb(color).darker()}
      data-index={index}
      onClick={(e) => handleClick(e)}
    />
    <text
      x={x0 < width / 2 ? x1 + 6 : x0 - 6 }
      y={(y1 + y0) / 2}
      style={{
        fill: d3.rgb(color).darker(),
        alignmentBaseline: 'middle',
        fontSize: 11,
        // textTransform: 'uppercase',
        textAnchor: x0 < width / 2 ? 'start' : 'end',
        pointerEvents: 'none',
        userSelect: 'none',
      }}
      >
      {name}
    </text>
  </>
);

const Link = ({
  data,
  width,
  setTooltip,
  tooltip,
  setSearchParams,
  filters,
}) => {
  const path = sankeyLinkHorizontal();
  function setOpacity(tooltipIndex) {
    if (!tooltipIndex) return 0.5;
    return tooltipIndex !== data.index ? 0.2 : 0.6;
  }
  async function clickOnLink(d) {
    setSearchParams({
      ...filters,
      business_unit: [d.source.id.toLowerCase()],
      locations: [d.target.id.toLowerCase()],
    });
  }
  return (
    <>
      <defs>
        <linearGradient
          id={`gradient-${data.index}`}
          gradientUnits="userSpaceOnUse"
          x1={data.source.x1}
          x2={data.target.x0}
        >
          <stop offset="0" stopColor={data.source.color} />
          <stop offset="100%" stopColor={data.target.color} />
        </linearGradient>
      </defs>
      <path
        d={path(data)}
        fill={'none'}
        stroke={`url(#gradient-${data.index})`}
        onClick={() => {
          clickOnLink(data);
        }}
        strokeOpacity={setOpacity(tooltip?.data?.index)}
        strokeWidth={width}
        onMouseOver={(e) => setTooltip({ data, transform: `translate(${e.clientX}px,${e.clientY}px)` })}
        onMouseOut={(e) => setTooltip({ transform: `translate(${e.clientX}px,${e.clientY}px)` })}
      />
    </>
  );
};

export default function Shankey({
  data, width, handleClick, setSearchParams, filters,
}) {
  const [tooltip, setTooltip] = useState();
  const graph = useRef();
  const height = data?.nodes?.length ? data.nodes.length * 35 : 280;
  const colors = d3.scaleOrdinal(d3.schemeCategory10);

  // const colors = (v) => d3.interpolateRdYlBu(v);
  function clickOnNode(e, d) {
    e.preventDefault();
    handleClick(d);
  }

  const shankey = d3sankey()
    .nodeId((d) => d.id)
    .nodeAlign(sankeyJustify)
    .nodeWidth(20)
    .nodePadding(5)
    // .nodeSort(null)
    .extent([[0, 0], [width, height]]);

  if (data.nodes) {
    graph.current = shankey({
      nodes: data.nodes.map((d, i) => ({
        ...d,
        color: colors(i / data.nodes.length),
      })),
      links: data.links.map((d) => ({ ...d })).sort((a, b) => (b.value - a.value)),
    });
    const { links, nodes } = graph.current;
    return (
      <div className={styles.shankey}>
        <svg width={`${width}px`} height={`${height}px`}>
          <g>
            {links.map((d) => <Link
              key={`shankey-link-${d.source.id}-${d.target.id}`}
              data={d}
              width={d.width}
              setTooltip={setTooltip}
              tooltip={tooltip}
              filters={filters}
              setSearchParams={setSearchParams}
              length={nodes.length}
              colors={colors}
            />)}
          </g>
          <g>
            {nodes.map((d) => <Rect
              key={`shankey-node-${d.id}`}
              id={d.id}
              index={d.index}
              x0={d.x0}
              x1={d.x1}
              y0={d.y0}
              y1={d.y1}
              handleClick={(e) => clickOnNode(e, d)}
              name={d.label}
              width={width}
              color={d.color}
            />)}
          </g>
        </svg>
        <div
          className={styles.tooltip}
          style={{
            opacity: tooltip?.data ? 1 : 0,
            transform: tooltip?.transform || 'translate(0,0)',
          }}
        >
          {tooltip?.data?.source?.index >= 0 && nodes
            && <p>
              <span
                style={{ color: d3.rgb(colors(tooltip.data.source.index / nodes.length)).darker() }}
              >
                {tooltip?.data?.source.label}
              </span>
              <span> &gt; </span>
              <span
                style={{ color: d3.rgb(colors(tooltip.data.target.index / nodes.length)).darker() }}
              >
                {tooltip?.data?.target.id}
              </span>
              <span> &gt; {tooltip?.data?.value}</span>
            </p>
          }
        </div>
      </div>
    );
  }
  return <div>Loading</div>;
}
