const style = {
  container: (provided) => ({
    ...provided,
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: '0 0 0 1px transparent',
    borderColor: '#BBBBBB',
    backgroundColor: '#F3F3F3',
    borderRadius: 6,
    minHeight: 32,
    borderStyle: 'none',
    '&:hover': {
      borderColor: state.isFocused ? '#7faae3' : '',
    },
  }),
  menuList: (provided) => ({ ...provided, maxHeight: 180 }),
  placeholder: (provided) => ({ ...provided, fontSize: '12px' }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: '12px',
    padding: '2px 4px',
  }),
  singleValue: (provided) => ({ ...provided, fontSize: '12px', marginTop: -2 }),
  multiValue: (provided, {
    data,
  }) => ({
    ...provided,
    fontSize: '12px',
    backgroundColor: data?.color ? data.color : '#7faae3',
    borderRadius: 4,
  }),
  multiValueLabel: (provided) => ({ ...provided, color: '#181844' }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '2px 4px',
    color: '#181844',
  }),
  option: (provided, { data, isSelected }) => ({
    ...provided,
    fontSize: '12px',
    padding: '4px 8px',
    backgroundColor: isSelected && data?.color ? data.color : '',
    '&:hover': {
      backgroundColor: '#7faae3',
      color: 'white',
    },
    ':active': {
      ...provided[':active'],
      backgroundColor: isSelected && data?.color ? data.color : '#181844',
    },
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '2px',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorContainer: (provided) => ({
    ...provided,
    padding: '2px',
  }),
};

export default style;
