import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { IoChevronBack } from 'react-icons/io5';
import styles from './Pie.module.scss';

export default function Pie({
  data,
  width,
  height,
  colorsRange,
  handleClick,
}) {
  const [tooltip, setTooltip] = useState();
  const [centeredData, setCenteredData] = useState();
  const ref = useRef();
  const cache = useRef(data);
  const createPie = d3
    .pie()
    .value((d) => d.value)
    .sort((a, b) => b.value - a.value);

  const outerRadius = height / 2.7;
  const innerRadius = outerRadius - 30;
  let centered = null;
  const total = data.map((d) => d.value).reduce((a, b) => a + b);
  const max = Math.max(...data.map((d) => d.value));
  const min = Math.min(...data.map((d) => d.value));

  function back() {
    setCenteredData();
    d3.select(ref.current).transition()
      .duration(750)
      .attr('transform', `translate(${(width / 2)},${outerRadius})`);
  }

  const createArc = d3
    .arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius);

  // const colors = d3.scaleOrdinal(d3.schemeCategory10);
  const colors = d3.scaleLinear()
    .domain([min, max])
    .range(colorsRange);
  // const colors = (v) => {
  //   const value = (v / data.length);
  //   console.log(value);
  //   return d3.interpolateRdYlBu(value);
  // };

  useEffect(() => {
    const initColors = data.filter((d) => d.label !== 'missing').map((d) => ({
      ...d,
      color: colors(d.value),
    }));
    const pieData = createPie(initColors.reverse());
    const prevPieData = createPie(cache.current);
    const group = d3.select(ref.current);
    const groupWithData = group.selectAll('g.arc').data(pieData, (d) => d.id);
    setTooltip();
    if (pieData.length === 1) {
      setTooltip(pieData[0]);
    }
    // const groupLabelWithData = group.selectAll('g.label').data(pieData);

    groupWithData.exit().remove();
    // groupLabelWithData.exit().remove();

    // const groupLabelWithUpdate = groupLabelWithData
    //   .enter()
    //   .append('g')
    //   .attr('class', 'label');

    const groupWithUpdate = groupWithData
      .enter()
      .append('g')
      .attr('class', 'arc');

    const path = groupWithUpdate
      .append('path')
      .merge(groupWithData.select('path.arc'));

    const arcTween = (d, i) => {
      // const interpolate = d3.interpolate(d.endAngle, d.startAngle);
      // return (t) => {
      //   interpolate(t);
      //   return createArc(d);
      // };

      const interpolator = data.length !== 1
        ? d3.interpolate(prevPieData[i], d)
        : d3.interpolate(d, d);
      return (t) => createArc(interpolator(t));
    };

    let text;

    path
      .attr('class', 'arc')
      .attr('id', (d) => d.data.id)
      .attr('fill', (d) => d.data.color)
      .attr('stroke', '#FFFFFF')
      .attr('stroke-width', 0.2)
      .on('click', (e, d) => {
        e.preventDefault();
        const isHight = (d.data.value / total) * 100 > 10;
        let x; let y; let
          k;
        if (isHight) {
          handleClick(d.data.id);
        }
        if (!isHight && d && !centered) {
          const centroid = createArc.centroid(d);
          [x, y] = centroid;
          k = 4;
          centered = d;
          setCenteredData(d);
          text.transition()
            .style('opacity', 0);
          group.transition()
            .duration(750)
            .attr('transform', `translate(${width / 2},${height / 2})scale(${k})translate(${-x},${-y})`);
        } else {
          handleClick(d.data.id);
          text.transition()
            .style('opacity', 1);
          group.transition()
            .duration(750)
            .attr('transform', `translate(${(width / 2)},${outerRadius})`);
          centered = null;
          setCenteredData();
        }
      })
      .on('mouseover', (e, d) => {
        group.selectAll('.arc')
          .transition()
          .style('opacity', (a) => (a.data.id === d.data.id ? 1 : 0.5));
        setTooltip(d);
      })
      .on('mouseout', () => {
        group.selectAll('.arc')
          .transition()
          .style('opacity', 1);
        if (pieData.length > 1) {
          setTooltip();
        }
      })
      .transition()
      .attrTween('d', arcTween);

    // const rect = groupLabelWithUpdate
    //   .append('rect')
    //   .merge(groupWithData.select('rect'));

    // rect
    //   .attr('width', 120)
    //   .attr('height', 20)
    //   .style('fill', '#FFFFFF')
    //   .style('opacity', (d) => ((d.data.value / total) * 100 > 10 && !centered ? 0.4 : 0))
    //   .transition()
    //   .attr('transform', (d) =>
    //  `translate(${createArc.centroid(d)[0] - 50},${createArc.centroid(d)[1] - 10})`)
    //   .text((d) => (
    //     (d.data.value / total) * 100 > 10 ? d.data.id : ''
    //   ));
    text = groupWithUpdate
      .append('text')
      .merge(groupWithData.select('text'));

    text
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr('font-weight', 'bold')
      .style('fill', '#181844')
      .style('font-size', 10)
      .transition()
      .attr('transform', (d) => `translate(${createArc.centroid(d)})`)
      .text((d) => (
        (d.data.value / total) * 100 > 10 && !centered ? d.data.id : ''
      ));

    cache.current = pieData;
  }, [data, width]);

  return (
    <div className={styles.pie}>
      {centeredData
        && <button
          onClick={() => back()}
          className={styles.back}>
          <IoChevronBack size={14}/>retour
        </button>
      }
      <svg width={width < 0 ? 0 : width} height={height}>
        <g
          ref={ref}
          transform={`translate(${(width / 2)},${outerRadius})`}
        />
      </svg>
      <div
          className={styles.tooltip}
          style={{
            opacity: tooltip?.data ? 1 : 0,
            transform: centeredData ? `translate(0px,${height / 6}px)` : `translate(0px,${height / 3.9}px)`,
          }}
        >
          {tooltip?.data?.value
            && <p>
              <span
                className={styles.value}
                style={{
                  color: tooltip.data.color,
                }}
              >
                {data.length !== 1
                  ? <>{((tooltip.data.value / total) * 100).toFixed(2)} %<br/></>
                  : <>100 %<br/></>
                }
              </span>
              <span>{tooltip?.data?.label}</span>
            </p>
          }
        </div>
    </div>
  );
}
