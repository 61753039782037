import {
  GET_DOCUMENTS,
  SET_DOCUMENTS_FILTERED,
  SET_MESSAGE,
  SET_ERROR,
  SET_PANEL_IS_OPEN,
  SET_FILTERS_IS_OPEN,
} from '../actions/types';

const initialState = {
  error: null,
  message: null,
  documentsList: null,
  documentsFiltered: null,
  optionsLists: null,
  isLoading: true,
  filtersIsOpen: false,
  panelIsOpen: false,
  filters: {
    document_name: [],
    product_name: [],
    product_family: [],
    product_type: [],
    business_unit: [],
    locations: [],
    materials: [],
    materialsValues: [],
    properties: [],
    propertiesValues: [],
  },
};
// eslint-disable-next-line default-param-last
export default function documentsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DOCUMENTS:
      return {
        ...state,
        documentsList: action.payload.documents,
        documentsFiltered: action.payload.documents.sort((a, b) => {
          if (a.product_name < b.product_name) { return -1; }
          if (a.product_name > b.product_name) { return 1; }
          return 0;
        }),
        optionsLists: action.payload.optionsLists,
        isLoading: false,
      };
    case SET_DOCUMENTS_FILTERED:
      return {
        ...state,
        documentsFiltered: action.payload.documents.sort((a, b) => {
          if (a.product_name < b.product_name) { return -1; }
          if (a.product_name > b.product_name) { return 1; }
          return 0;
        }),
        filters: action.payload.filters,
        isLoading: false,
      };
    case SET_MESSAGE:
      return { ...state, message: action.payload };
    case SET_PANEL_IS_OPEN:
      return { ...state, panelIsOpen: action.payload };
    case SET_FILTERS_IS_OPEN:
      return { ...state, filtersIsOpen: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
}
