import React from 'react';
import { useSpring, animated } from 'react-spring';
import { useDispatch, useSelector } from 'react-redux';
import { IoMdHelpCircle } from 'react-icons/io';

import tutorial from '../../assets/file/Notice utilisation.pdf';
import icon from '../../assets/images/lib.svg';
import iconWhite from '../../assets/images/lib-white.svg';
import { SET_PANEL_IS_OPEN } from '../../actions/types';
import StyledSelect from '../../lib/HooksFormFields/StyledSelect';
import DocumentRow from '../DocumentRow/DocumentRow';

import styles from './DocumentPanel.module.scss';

export default function DocumentPanel({
  watch,
  control,
  handleChangeQuery,
}) {
  const dispatch = useDispatch();
  const {
    documentsFiltered,
    panelIsOpen,
    optionsLists,
  } = useSelector((state) => state.documentsReducer);
  const count = useSpring({ val: documentsFiltered?.length || 0, from: { val: 0 } });
  function setPanelIsOpen(e, val) {
    e.preventDefault();
    dispatch({
      type: SET_PANEL_IS_OPEN,
      payload: val,
    });
  }
  console.log(documentsFiltered);
  return (
    <div className={`${styles.container} ${panelIsOpen ? styles.open : ''}`}>
      <div className={styles.content}>
        <button className={styles.close} onClick={(e) => setPanelIsOpen(e, false)}>
          Fermer
        </button>
        <button className={styles.toggle} onClick={(e) => setPanelIsOpen(e, !panelIsOpen)}>
          <div className={styles.icon}>
            <img src={iconWhite} alt="icon" />
          </div>
          <div className={styles.count}>
            <animated.span className="number">
              {count.val.to((val) => Math.floor(val))}
            </animated.span>
            <p>docs</p>
          </div>
        </button>
        <a href={tutorial} target="_blank" rel="noreferrer">
          <button className={styles.help}>
            <IoMdHelpCircle size={22}/>
            Tuto
          </button>
        </a>
        <header>
          <div className={styles.icon}>
            <img src={icon} alt="icon" />
          </div>
          <h2>
            <animated.span className="number">
              {count.val.to((val) => Math.floor(val))}
            </animated.span>
          </h2>
          <p>Documents</p>
        </header>
        <div className={styles.search}>
          <div className={`${styles.select}`}>
            <label htmlFor="document_name">Documents name</label>
            <StyledSelect
              control={control}
              name="document_name"
              isMulti
              placeholder="Search"
              value={watch('document_name')}
              onChange={(array) => handleChangeQuery('document_name', array)}
              options={optionsLists?.document_name}
              />
          </div>
          <div className={`${styles.select}`}>
            <label htmlFor="product_name">Product name</label>
            <StyledSelect
              control={control}
              name="product_name"
              isMulti
              placeholder="Search"
              value={watch('product_name')}
              onChange={(array) => handleChangeQuery('product_name', array)}
              options={optionsLists?.product_name}
              />
          </div>

        </div>
        <div className={styles.list}>
          {documentsFiltered?.map((d, i) => <DocumentRow
            key={d._id + i}
            document={d}
          />)}
        </div>
      </div>
    </div>
  );
}
