import React from 'react';
import { RiLoader2Fill } from 'react-icons/ri';
import styles from './NoData.module.scss';

export default function NoData({ isNoData, isLoading }) {
  return (
    <div className={`${styles.container} ${isNoData || isLoading ? '' : styles.hide}`}>
      {isNoData && <p>no data available</p>}
      {isLoading && <div className={styles.loader}>
        <RiLoader2Fill size={40} />
      </div>}
    </div>
  );
}
