export function createOptions(products, marketAreaApp = null, options = null) {
  // console.time('createOptions');
  const optionsLists = {
    document_name: [],
    product_name: [],
    product_family: [],
    product_type: [],
    business_unit: [],
    areas: [],
    markets: [],
    applications: [],
    locations: [],
    materials: [],
    materialsOptions: options?.materialsOptions || [],
    properties: [],
    propertiesOptions: options?.propertiesOptions || [],
  };
  products.forEach((p) => {
    if (!optionsLists.document_name.find((opt) => opt.value === p.document_name.toLowerCase())) {
      optionsLists.document_name.push({
        label: p.document_name, value: p.document_name.toLowerCase(),
      });
    }
    if (!optionsLists.product_name.find((opt) => opt.value === p.product_name.toLowerCase())) {
      optionsLists.product_name.push({
        label: p.product_name, value: p.product_name.toLowerCase(),
      });
    }
    if (!optionsLists.product_family.find((opt) => opt.value === p.product_family.toLowerCase())) {
      optionsLists.product_family.push({
        label: p.product_family, value: p.product_family.toLowerCase(),
      });
    }
    if (p.product_type
      && !optionsLists.product_type.find((opt) => opt.value === p.product_type.toLowerCase())) {
      optionsLists.product_type.push({
        label: p.product_type, value: p.product_type.toLowerCase(),
      });
    }
    if (p?.business_unit
      && !optionsLists.business_unit.find((opt) => opt.value === p.business_unit.toLowerCase())) {
      optionsLists.business_unit.push({
        label: p.business_unit, value: p.business_unit.toLowerCase(),
      });
    }
    if (p?.locations?.length > 0) {
      p.locations.forEach((m) => {
        if (!optionsLists.locations.find((opt) => opt.value === m.toLowerCase())) {
          optionsLists.locations.push({ label: m, value: m.toLowerCase() });
        }
      });
    }
    p.materials.forEach((m) => {
      if (!optionsLists.materials.find((opt) => opt.value === m.toLowerCase())) {
        optionsLists.materials.push({ label: m, value: m.toLowerCase() });
      }
      if (!options) {
        if (p[m] && p[m].length) {
          const property = p[m][0];
          const found = optionsLists.materialsOptions.find(
            (opt) => opt.label === m,
          );
          const index = optionsLists.materialsOptions.findIndex(
            (opt) => opt.value === m.toLowerCase(),
          );
          let values = [];
          if (property.min) {
            values.push(property.min);
          }
          if (property.value) {
            values.push(property.value);
          }
          if (property.max) {
            values.push(property.max);
          }
          values = values.sort((a, b) => a - b);

          if (values.length) {
            if (found) {
              const updatedFound = { ...found };
              const min = values[0];
              const max = values[values.length - 1];
              if (found.min > min) {
                updatedFound.min = min;
              }
              if (found.max < max) {
                updatedFound.max = max;
              }
              optionsLists.materialsOptions[index] = updatedFound;
            } else {
              optionsLists.materialsOptions.push({
                label: m,
                value: m.toLowerCase(),
                unit: property.unit || '',
                min: values[0],
                max: values[values.length - 1],
              });
            }
          }
        }
      }
    });
    p.properties.forEach((prop) => {
      if (!optionsLists.properties.find((opt) => opt.value === prop)) {
        optionsLists.properties.push({ label: prop, value: prop });
      }
      if (!options) {
        if (p[prop] && p[prop].length) {
          const property = p[prop][0];
          const found = optionsLists.propertiesOptions.find(
            (opt) => opt.label === prop,
          );
          const index = optionsLists.propertiesOptions.findIndex((opt) => opt.value === prop);
          let values = [];
          if (property.min) {
            values.push(property.min);
          }
          if (property.value) {
            values.push(property.value);
          }
          if (property.max) {
            values.push(property.max);
          }
          values = values.sort((a, b) => a - b);

          if (values.length) {
            if (found) {
              const updatedFound = { ...found };
              const min = values[0];
              const max = values[values.length - 1];
              if (found.min > min) {
                updatedFound.min = min;
              }
              if (found.max < max) {
                updatedFound.max = max;
              }
              optionsLists.propertiesOptions[index] = updatedFound;
            } else {
              optionsLists.propertiesOptions.push({
                label: prop,
                value: prop.toLowerCase(),
                unit: property.unit || '',
                min: values[0],
                max: values[values.length - 1],
              });
            }
          }
        }
      }
    });
    if (marketAreaApp) {
      p.market_area_app.filter((item) => {
        if (marketAreaApp.Market
          && item.Market
          && marketAreaApp.Market !== item.Market) {
          return null;
        }
        return p;
      }).filter((item) => {
        if (marketAreaApp.Area
          && item.Area
          && marketAreaApp.Aera !== item.Aera) {
          return null;
        }
        return p;
      }).filter((item) => {
        if (marketAreaApp.Application
          && item.Application
          && marketAreaApp.Application !== item.Application) {
          return null;
        }
        return p;
      }).forEach((prop) => {
        if (!marketAreaApp.Area
          && prop.Area
          && !optionsLists.areas.find((opt) => opt.value === prop.Area)) {
          optionsLists.areas.push({ label: prop.Area, value: prop.Area, color: '#154F94' });
        }
        if (!marketAreaApp.Market
          && !optionsLists.markets.find((opt) => opt.value === prop.Market.toLowerCase())) {
          optionsLists.markets.push({ label: prop.Market, value: prop.Market.toLowerCase(), color: '#48B1B1' });
        }
        if (!marketAreaApp.Application
          && prop.Application
          && !optionsLists.applications.find(
            (opt) => opt.value === prop.Application.toLowerCase(),
          )) {
          optionsLists.applications.push({ label: prop.Application, value: prop.Application.toLowerCase(), color: '#F16E30' });
        }
      });
    }
  });
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(optionsLists)) {
    optionsLists[key] = value.sort((a, b) => {
      if (a.value < b.value) { return -1; }
      if (a.value > b.value) { return 1; }
      return 0;
    });
  }
  return optionsLists;
}

export const arrayChecker = (arr, target) => target.every((v) => arr.includes(v));
